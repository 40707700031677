export interface Client {
  codeClient: string;
  libelleClient: string;
}

export interface SortFilter {
  orderingField: string;
  orderingDirection: number;
}

export interface PageFilter {
  numeroPage: number;
  nbrParPage: number;
}

export interface DocumentAction {
  id: string;
  action: PdfAction;
}

export enum PdfAction {
  telecharger,
  visionner,
}
