import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import saveAs from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  private http = inject(HttpClient);

  downloadFile(
    url: string,
    defaultFileName: string,
    queryParams: HttpParams = new HttpParams(),
  ): Observable<DownloadedFile> {
    return this.http.get(url, { responseType: 'blob', observe: 'response', params: queryParams }).pipe(
      map(response => {
        const blob = response.body as Blob;
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = this.extractFileName(contentDisposition) || defaultFileName;

        return { filename: filename, content: blob };
      }),
    );
  }

  saveDownloadedFile(file: DownloadedFile): void {
    saveAs(file.content, file.filename);
  }

  private extractFileName(contentDisposition: string | null): string | null {
    if (!contentDisposition) return null;
    const match = contentDisposition.match(/filename="?([^;"]+)/);
    return match ? match[1] : null;
  }
}

export interface DownloadedFile {
  filename: string;
  content: Blob;
}
