import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, map, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DocumentAction, PdfAction } from '../redditions/global/global-reddition.interface';
import { DownloadedFile, DownloadService } from '../../partage/services/download.service';

@Injectable({
  providedIn: 'root',
})
export class FactureService {
  constructor(
    private _httpClient: HttpClient,
    private _downloadService: DownloadService,
  ) {}

  redditionAction = signal<DocumentAction | undefined>(undefined);
  acompteAction = signal<DocumentAction | undefined>(undefined);

  downloadedFile = signal<DownloadedFile | undefined>(undefined);
  voirPdf = signal<boolean>(false);

  private acompteAction$ = toSignal(
    toObservable(this.acompteAction).pipe(
      filter(Boolean),
      switchMap(documentAction => {
        const url = `${environment.backendUrl}facturation/justificatif-acompte/acompte-reddition/${documentAction.id}`;
        return this._downloadService.downloadFile(url, 'justificatif-acompte.pdf').pipe(
          map(file => {
            this.redditionAction.set(undefined);
            this.documentAction(file, documentAction);
          }),
        );
      }),
    ),
  );

  private redditionAction$ = toSignal(
    toObservable(this.redditionAction).pipe(
      filter(Boolean),
      switchMap(documentAction => {
        const url = `${environment.backendUrl}facturation/facture/reddition/${documentAction.id}`;
        return this._downloadService.downloadFile(url, 'facture.pdf').pipe(
          map(file => {
            this.redditionAction.set(undefined);
            this.documentAction(file, documentAction);
          }),
        );
      }),
    ),
  );

  private documentAction(file: DownloadedFile, documentAction: DocumentAction) {
    if (documentAction.action === PdfAction.telecharger) {
      this._downloadService.saveDownloadedFile(file);
    } else if (documentAction.action === PdfAction.visionner) {
      this.visionnerPdf(file);
    }
  }

  private visionnerPdf(file: DownloadedFile): void {
    this.downloadedFile.set(file);
    this.voirPdf.set(true);
  }

  telechargerDocumentCourant(): void {
    const document = this.downloadedFile();
    if (document) {
      this._downloadService.saveDownloadedFile(document);
    }
  }
}
